// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "x_gK g_gK g_cz g_cH";
export var navbarDividedRight = "x_gL g_gL g_cz";
export var menuLeft = "x_s1 g_gH g_cz g_fw g_cP";
export var menuRight = "x_s2 g_gH g_cz g_fw g_cP";
export var menuCenter = "x_s3 g_gJ g_gH g_cz g_fw g_cP g_bz g_cD";
export var menuDivided = "x_n7 g_gJ g_gH g_cz g_fw g_cP g_bz g_cD";
export var navbarItem = "x_n8 g_cx";
export var navbarLogoItemWrapper = "x_gQ g_gQ g_cC g_cP";
export var burgerToggle = "x_s4 g_g4 g_cy g_cs g_b2";
export var burgerToggleOpen = "x_s5 g_g4 g_cy g_cs g_b6";
export var burgerInput = "x_s6 g_gV g_bB g_cy g_b3 g_dc g_fl g_ct";
export var burgerLine = "x_s7 g_gT g_cy g_b2 g_bB g_cm g_dp";
export var burgerMenuLeft = "x_s8 g_gZ g_gY g_gW g_gX g_b3 g_dc g_fm g_cs g_fw";
export var burgerMenuRight = "x_s9 g_g0 g_gY g_gW g_gX g_b3 g_dc g_fm g_cs g_fw";
export var burgerMenuCenter = "x_tb g_g1 g_gY g_gW g_gX g_b3 g_dc g_fm g_cs g_fw";
export var burgerMenuDivided = "x_tc g_gZ g_gY g_gW g_gX g_b3 g_dc g_fm g_cs g_fw";
export var btnWrapper = "x_m g_dX g_dQ g_cz g_cH g_bz";
export var cancelBtn = "x_td g_c1 g_dD g_fB";
export var icon = "x_pZ";
export var secondary = "x_tf g_cC g_cP";