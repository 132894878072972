// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qZ g_hn g_ds";
export var heroHeaderCenter = "q_hp g_hp g_ds g_fw";
export var heroHeaderRight = "q_hq g_hq g_ds g_fx";
export var heroParagraphLeft = "q_q0 g_hk g_dw";
export var heroParagraphCenter = "q_hl g_hl g_dw g_fw";
export var heroParagraphRight = "q_hm g_hm g_dw g_fx";
export var heroBtnWrapperLeft = "q_q1 g_m g_fX g_bz g_cz g_cG";
export var heroBtnWrapperCenter = "q_q2 g_fY g_fX g_bz g_cz g_cD";
export var heroBtnWrapperRight = "q_q3 g_fZ g_fX g_bz g_cz g_cH";
export var overlayBtnWrapper = "q_q4 g_hj g_b3 g_cb g_cc g_cd g_cn g_dc";
export var design4 = "q_q5 g_hh g_b3 g_cb g_cc g_cn";
export var heroExceptionSmall = "q_q6 w_q6";
export var heroExceptionNormal = "q_q7 w_q7";
export var heroExceptionLarge = "q_q8 w_q8";
export var Title1Small = "q_q9 w_q9 w_rT w_rV";
export var Title1Normal = "q_rb w_rb w_rT w_rW";
export var Title1Large = "q_rc w_rc w_rT w_rX";
export var BodySmall = "q_rd w_rd w_rT w_sb";
export var BodyNormal = "q_rf w_rf w_rT w_sc";
export var BodyLarge = "q_rg w_rg w_rT w_sd";