// extracted by mini-css-extract-plugin
export var carouselContainer = "B_tw g_bz g_bL g_z g_b2";
export var carouselContainerCards = "B_tx B_tw g_bz g_bL g_z g_b2";
export var carouselContainerSides = "B_ty g_bz g_bL g_b2";
export var prevCarouselItem = "B_tz g_bz g_bL g_b3 g_bn";
export var prevCarouselItemL = "B_tB B_tz g_bz g_bL g_b3 g_bn";
export var moveInFromLeft = "B_tC";
export var prevCarouselItemR = "B_tD B_tz g_bz g_bL g_b3 g_bn";
export var moveInFromRight = "B_tF";
export var selectedCarouselItem = "B_tG g_bz g_bL g_b2 g_z";
export var selectedCarouselItemL = "B_tH B_tG g_bz g_bL g_b2 g_z";
export var selectedCarouselItemR = "B_tJ B_tG g_bz g_bL g_b2 g_z";
export var nextCarouselItem = "B_tK g_bz g_bL g_b3 g_bn";
export var nextCarouselItemL = "B_tL B_tK g_bz g_bL g_b3 g_bn";
export var nextCarouselItemR = "B_tM B_tK g_bz g_bL g_b3 g_bn";
export var arrowContainer = "B_tN g_bL g_b3 g_fB g_cl g_b7 g_c1";
export var prevArrowContainer = "B_tP B_tN g_bL g_b3 g_fB g_cl g_b7 g_c1";
export var prevArrowContainerHidden = "B_tQ B_tP B_tN g_bL g_b3 g_fB g_cl g_b7 g_c1 g_bn";
export var nextArrowContainer = "B_tR B_tN g_bL g_b3 g_fB g_cl g_b7 g_c1";
export var carouselContainerProducts = "B_tS";
export var nextArrowContainerHidden = "B_tT B_tR B_tN g_bL g_b3 g_fB g_cl g_b7 g_c1 g_bn";
export var arrow = "B_ls g_b3";
export var prevArrow = "B_tV B_ls g_b3";
export var nextArrow = "B_tW B_ls g_b3";
export var carouselIndicatorContainer = "B_tX g_bz g_fw g_cl g_cz g_cD g_cP g_cJ g_b3";
export var btnsContainer = "B_tY g_bz g_cz g_cF";
export var carouselText = "B_tZ g_fq g_cz g_cD g_cP g_c1 g_fB g_dD";
export var carouselTextInactive = "B_t0 B_tZ g_fq g_cz g_cD g_cP g_c1 g_fB g_dD";
export var indicator = "B_t1 g_c7";
export var carouselIndicator = "B_t2 B_t1 g_c7";
export var carouselIndicatorSelected = "B_t3 B_t1 g_c7";
export var arrowsContainerTopRight = "B_t4 g_b3 g_cl g_cC";
export var arrowsContainerBottomLeft = "B_t5 g_b3 g_cl g_cC";
export var arrowsContainerSides = "B_t6 g_b3 g_cl g_cC";
export var smallArrowsBase = "B_t7 g_cT g_cz g_cD g_cP g_c1 g_fB g_dD";
export var smallArrowContainer = "B_t8 B_t7 g_cT g_cz g_cD g_cP g_c1 g_fB g_dD";
export var smallArrowContainerPrev = "B_t9 B_t8 B_t7 g_cT g_cz g_cD g_cP g_c1 g_fB g_dD";
export var smallArrowContainerSmall = "B_vb B_t7 g_cT g_cz g_cD g_cP g_c1 g_fB g_dD";
export var smallArrowContainerPrevSmall = "B_vc B_vb B_t7 g_cT g_cz g_cD g_cP g_c1 g_fB g_dD";
export var icon = "B_pZ";
export var iconSmall = "B_vd";
export var multipleWrapper = "B_vf g_cC g_cF g_z";
export var multipleImage = "B_vg g_cC";
export var sidesPrevContainer = "B_vh B_t8 B_t7 g_cT g_cz g_cD g_cP g_c1 g_fB g_dD g_gh g_b3 g_cl g_dD";
export var sidesNextContainer = "B_vj B_t8 B_t7 g_cT g_cz g_cD g_cP g_c1 g_fB g_dD g_gh g_b3 g_cl g_dD";