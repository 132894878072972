// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_tg";
export var lbContainerInner = "y_th";
export var movingForwards = "y_tj";
export var movingForwardsOther = "y_tk";
export var movingBackwards = "y_tl";
export var movingBackwardsOther = "y_tm";
export var lbImage = "y_tn";
export var lbOtherImage = "y_tp";
export var prevButton = "y_tq";
export var nextButton = "y_tr";
export var closing = "y_ts";
export var appear = "y_tt";