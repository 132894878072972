// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qt g_gj g_cG g_fv";
export var alignLeft = "p_p4 g_gj g_cG g_fv";
export var alignDiscCenter = "p_qv g_gk g_cD g_fw";
export var alignCenter = "p_cP g_gk g_cD g_fw";
export var alignDiscRight = "p_qw g_gl g_cH g_fx";
export var alignRight = "p_p5 g_gl g_cH g_fx";
export var footerContainer = "p_qx g_fS g_cW";
export var footerContainerFull = "p_qy g_fQ g_cW";
export var footerHeader = "p_kY g_kY";
export var footerTextWrapper = "p_qz g_bz";
export var footerDisclaimerWrapper = "p_k3 g_k3 g_dk";
export var badgeWrapper = "p_qB g_bz g_cz g_cP g_cF g_cJ";
export var footerDisclaimerRight = "p_k4 g_k4 g_cz";
export var footerDisclaimerLeft = "p_k5 g_k5 g_cz";
export var verticalTop = "p_qC g_cz g_cJ g_cN g_cL";
export var firstWide = "p_qD";
export var disclaimer = "p_qF";
export var socialDisclaimer = "p_qG";
export var left = "p_qH";
export var wide = "p_qJ g_dy";
export var right = "p_qK g_cK";
export var line = "p_gd g_gf g_dv";
export var badgeDisclaimer = "p_qL g_cC g_cP g_cJ";
export var badgeContainer = "p_qM g_cz g_cH g_cP";
export var badge = "p_qN";
export var padding = "p_qP g_d7";
export var end = "p_qQ g_cH";
export var linkWrapper = "p_qR g_fB";
export var link = "p_s g_fB";
export var colWrapper = "p_qS g_dx";
export var media = "p_qT g_cx g_fy";
export var itemRight = "p_qV";
export var itemLeft = "p_qW";
export var itemCenter = "p_qX";
export var exceptionWeight = "p_qY w_sk";